import { combineReducers } from 'redux';

// reducer import
import customizationReducer from './customizationReducer';
import mapReducer from './mapReducer';
import userReducer from './userReducer';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
  customization: customizationReducer,
  map: mapReducer,
  user: userReducer
});

export default reducer;
