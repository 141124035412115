import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Chart } from 'react-google-charts';
import spd from '../../../../../../Cranberry_dataset/spd.json';

const prepareChartData = (data, tmcId) => {
  const chartData = [
    [
      'Time',
      'Observed',
      'Prediction',
      { type: 'number', role: 'interval' },
      { type: 'number', role: 'interval' },
      { type: 'number', role: 'interval' },
      { type: 'number', role: 'interval' }
    ]
  ];

  const tmcData = data[tmcId];
  if (!tmcData) return chartData; // Handle case where tmcId is not found

  const allKeys = new Set([
    ...Object.keys(tmcData.pre),
    ...Object.keys(tmcData.pro),
    ...Object.keys(tmcData['05']),
    ...Object.keys(tmcData['25']),
    ...Object.keys(tmcData['75']),
    ...Object.keys(tmcData['95'])
  ]);

  const sortedKeys = Array.from(allKeys).sort((a, b) => parseInt(a) - parseInt(b));

  sortedKeys.forEach((time) => {
    chartData.push([
      `${time}`,
      parseFloat(tmcData.pre[time]),
      parseFloat(tmcData.pro[time]),
      parseFloat(tmcData['05'][time]),
      parseFloat(tmcData['25'][time]),
      parseFloat(tmcData['75'][time]),
      parseFloat(tmcData['95'][time])
    ]);
  });

  return chartData;
};

export default function SpeedChart() {
  const selectedTmcId = useSelector((state) => state.map.selectedTmcId); // Get selected TMC ID from the store
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    if (selectedTmcId) {
      setChartData(prepareChartData(spd, selectedTmcId));
    }
  }, [selectedTmcId]);

  return (
    <Chart
      width={'100%'}
      height={'400px'}
      chartType="LineChart"
      loader={<div>Loading Chart</div>}
      data={chartData}
      options={{
        title: `Speed Prediction for TMC ${selectedTmcId || 'N/A'}`,
        hAxis: { title: 'Time (Min)' },
        vAxis: { title: 'Speed (Mph)' },
        legend: { position: 'bottom' },
        series: {
          0: { color: 'red' }, // Observed data
          1: { color: '#0077b6' } // Predicted data
        },
        intervals: { style: 'area', color: 'series-color' }, // Use the color of the series
        interval: {
          0: { style: 'bars', color: '#333' }, // Optional styling for intervals
          1: { style: 'bars', color: '#333' }
        }
      }}
    />
  );
}
