import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { setDisplayOptions } from '../../../../../store/actions';

export default function DisplayCheckBox() {
  const displayOptions = useSelector((state) => state.map.displayOptions);
  const dispatch = useDispatch();

  const handleChange = (event) => {
    dispatch(
      setDisplayOptions({
        ...displayOptions,
        [event.target.name]: event.target.checked
      })
    );
  };

  return (
    <FormGroup>
      <FormControlLabel
        control={<Checkbox checked={displayOptions.incidentBlocks} onChange={handleChange} name="incidentBlocks" />}
        label="Incident Blocks"
      />
      <FormControlLabel
        control={<Checkbox checked={displayOptions.rcrsIncidents} onChange={handleChange} name="rcrsIncidents" />}
        label="RCRS Incidents"
      />
      <FormControlLabel
        control={<Checkbox checked={displayOptions.wazeAlerts} onChange={handleChange} name="wazeAlerts" />}
        label="Waze Alerts"
      />
    </FormGroup>
  );
}
