import * as React from 'react';
import PropTypes from 'prop-types';

// material-ui
import { Typography, Tabs, Tab, Box } from '@mui/material';

// components
import Predictions from '../PredictionSection';

// assets
import TuneIcon from '@mui/icons-material/Tune';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import Controls from '../ControlSection';

// ==============================|| SIDEBAR MENU LIST ||============================== //

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`vertical-tabpanel-${index}`} aria-labelledby={`vertical-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component="span">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`
  };
}

function MenuList() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: '100%' }}>
      <Tabs
        orientation="vertical"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs"
        sx={{ borderRight: 1, borderColor: 'divider', width: '6vw' }}
      >
        <Tab sx={{ width: '4vw', display: 'flex', justifyContent: 'left' }} icon={<TroubleshootIcon />} {...a11yProps(0)} />
        <Tab sx={{ width: '4vw', display: 'flex', justifyContent: 'left' }} icon={<TuneIcon />} {...a11yProps(1)} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <Predictions />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Controls />
      </TabPanel>
    </Box>
  );
}
export default MenuList;
