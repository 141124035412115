import * as React from 'react';

// material-ui
import { Container, Typography, Stack, Grid, Box } from '@mui/material';
import TrafficPrediction from './TrafficPredictionSection';

// third-party
import { format } from 'date-fns';

import lastUpdate from '../../../../Cranberry_dataset/lastUpdate.json';

function Predictions() {
  const currentTime = new Date();

  return (
    <Container disableGutters>
      <Stack spacing={2} width="23vw">
        <Typography component={'span'} variant="h1" color="#0066CC">
          Predictions
        </Typography>
        <Typography component={'span'} variant="h3">
          Contingency Traffic Plans
        </Typography>
        <Grid container justifyContent="space-between">
          <Grid item xs={6}>
            <Typography component={'span'} variant="h5" color="#939393">
              Current time
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography component={'span'} variant="h5" color="#939393">
              {format(currentTime, 'yyyy-MM-dd HH:mm:ss')}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography component={'span'} variant="h5" color="#939393">
              Last update
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography component={'span'} variant="h5" color="#939393">
              {lastUpdate.ts}
            </Typography>
          </Grid>
        </Grid>
        <Typography component={'span'} variant="h3">
          Recommend Action
        </Typography>
        <Box sx={{ borderRadius: 1, bgcolor: '#EBF5FF', p: 1 }}>
          <Typography component={'span'} variant="body1" color="#0066CC">
            No signal plan is recommended (free-flow on I79/76 highways)
          </Typography>
        </Box>
        {/* <Divider /> */}
        <Typography component={'span'} variant="h3">
          Traffic predictions
        </Typography>
        <TrafficPrediction />
      </Stack>
    </Container>
  );
}

export default Predictions;
