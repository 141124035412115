import * as React from 'react';

// material-ui
import { Container, Typography, Stack } from '@mui/material';
import ScaleSelect from './ScaleSelect';
import ForecastingSlider from './ForecastingSlider';
import DisplayCheckBox from './DisplayCheckBox';

function Controls() {
  return (
    <Container disableGutters>
      <Stack spacing={2} width="23vw">
        <Typography component="span" variant="h1" color="#0066CC">
          Map Control
        </Typography>
        <Typography component="span" variant="h3">
          Map scale
        </Typography>
        <ScaleSelect />
        <Typography component="span" variant="h3">
          Forecasting horizon
        </Typography>
        <ForecastingSlider />
        <Typography component="span" variant="h3">
          Display
        </Typography>
        <DisplayCheckBox />
      </Stack>
    </Container>
  );
}

export default Controls;
