import * as React from 'react';

import { Button, Grid } from '@mui/material';
import { Link } from 'react-router-dom';

function NavSection() {
  return (
    <Grid container spacing={2}>
      <Grid item>
        <Button component={Link} to="/document/userguide" variant="text" sx={{ color: 'black' }}>
          Documentation
        </Button>
      </Grid>
      <Grid item>
        <Button component={Link} to="/map" variant="text" sx={{ color: 'black' }}>
          Map
        </Button>
      </Grid>
      <Grid item>
        <Button component={Link} to="/reports" variant="text" sx={{ color: 'black' }}>
          Reports
        </Button>
      </Grid>
    </Grid>
  );
}

export default NavSection;
