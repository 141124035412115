import * as React from 'react';
import PropTypes from 'prop-types';

// material-ui
import { Typography, Tabs, Tab, Box } from '@mui/material';
import SpeedChart from './SpeedChart';
import CongestionChart from './CongestionRateChart';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`horizonal-tabpanel-${index}`} aria-labelledby={`horizonal-tab-${index}`} {...other}>
      {value === index && (
        <Box>
          <Typography component="span">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

export default function TrafficPrediction() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ bgcolor: 'background.paper', width: '100%' }}>
      <Tabs value={value} onChange={handleChange}>
        <Tab label="Speed" />
        <Tab label="Congestion Rate" />
      </Tabs>
      <TabPanel value={value} index={0}>
        <SpeedChart />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <CongestionChart />
      </TabPanel>
    </Box>
  );
}
