import * as React from 'react';

// material-ui
import { Box, InputLabel, MenuItem, FormControl, Select } from '@mui/material';

import { useDispatch } from 'react-redux';
import { setMapCenter } from '../../../../../store/actions';

const scales = [
  {
    value: 'cranberry',
    label: 'Cranberry Township'
  },
  {
    value: 'pittsburgh',
    label: 'Pittsburgh'
  }
];

export default function ScaleSelect() {
  const [scaleName, setSacleName] = React.useState('cranberry');
  const dispatch = useDispatch();
  const handleChange = (event) => {
    dispatch(setMapCenter(event.target.value));
    setSacleName(event.target.value);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <FormControl fullWidth>
        <InputLabel id="select-label">.</InputLabel>
        <Select labelId="select-label" id="select" value={scaleName} displayEmpty label="Scale Name" onChange={handleChange}>
          {scales.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
